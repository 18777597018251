"use client";


import {
  FC,
  PropsWithChildren,
  createContext,
  useState,
} from "react";


export const SearchBarContext = createContext<{
    search?: string;
    employeeType?: string;
    placeId?: string;
    setSearch: (search?: string) => void;
    setEmployeeType: (employeeType?: string) => void;
    setPlaceId: (placeId?: string) => void;
    //@ts-ignore
}>(null);

export const SearchBarProvider: FC<PropsWithChildren> = ({ children }) => {
    const [search, setSearch] = useState<string>();
    const [employeeType, setEmployeeType] = useState<string>();
    const [placeId, setPlaceId] = useState<string>();
    return (
      <SearchBarContext.Provider
        value={{
          search,
          employeeType,
          placeId,
          setSearch,
          setEmployeeType,
          setPlaceId,
        }}
      >
        {children}
      </SearchBarContext.Provider>
    );
  };