import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { getCookie } from "cookies-next";
import logger from "~/utils/logger";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      logger.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  }
  if (networkError) logger.error(`[Network error]: ${networkError}`);
});

const httpLink = new HttpLink({
  uri: `${process.env.NEXT_PUBLIC_APP_URL}/api/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = getCookie("access_token");

  if (token) {
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : "",
      },
    };
  }

  return {
    headers,
  };
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({ addTypename: false }),
});

export default apolloClient;
