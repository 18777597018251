import React, { createContext, useContext, useEffect, useState } from "react";
import { useSession } from "next-auth/react";
import { useNavbarContext } from "~/scalis-components/core/navbar/navbar-context";
import { CompanyWorkspace } from "~/types/auth/TokenPayload";

interface WorkspaceData {
  subdomain: string | null;
  workspace: CompanyWorkspace | null;
}

const WorkspaceContext = createContext<WorkspaceData | null>(null);

export const useWorkspace = () => {
  const context = useContext(WorkspaceContext);
  if (!context) {
    throw new Error("useWorkspace must be used within a WorkspaceProvider");
  }
  return context;
};

export const WorkspaceProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { data: session } = useSession();
  const [workspaceData, setWorkspaceData] = useState<WorkspaceData | null>(
    null,
  );

  const {
    setCurrentWorkspace: setNavbarCurrentWorkspace,
    setWorkspaces: setNavbarWorkspaces,
  } = useNavbarContext();

  useEffect(() => {
    if (session?.user) {
      // Get Workspace from Session / Token
      const workspace = session.user.workspace ?? null;

      setNavbarWorkspaces(workspace?.companies ?? []);

      // Set current company in Navbar
      if (workspace?.currentCompany) {
        setNavbarCurrentWorkspace(workspace.currentCompany);
      }
    }
  }, [session, setNavbarCurrentWorkspace, setNavbarWorkspaces]);

  return (
    <WorkspaceContext.Provider value={workspaceData}>
      {children}
    </WorkspaceContext.Provider>
  );
};
